import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './summary.css'

import BC from '../../Images/summaryBC.webp'
import summaryImage from '../../Images/summaryImage.png'
import forbes from '../../Images/forbes.png'
import NewTimes from '../../Images/NewTimes.png'
import business from '../../Images/business.png'

import { ReactComponent as Logo } from '../../icons/Logo.svg'
import { ReactComponent as Pursuitful } from '../../icons/Pursuitful.svg'
import { ReactComponent as SummaryOne } from '../../icons/SummaryOne.svg'
import { ReactComponent as SummaryUnlimited } from '../../icons/SummaryUnlimited.svg'
import { ReactComponent as SummaryDaily } from '../../icons/SummaryDaily.svg'
import { ReactComponent as Close } from '../../icons/Close.svg'

import ReactGA from 'react-ga4'

const Summary = () => {
  const navigate = useNavigate()
  const [showPop, setShowPop] = useState(false)

  const showPopup = () => {
    setShowPop(true)
  }

  useEffect(() => {
    ReactGA.event({ category: 'page', action: 'Summary' })
  }, [])
  return (
    <>
      <div className='summaryBC'>
        <img src={BC} alt='' className='BCImage' />
      </div>
      <div className='BC'>
        <div className='BCSummaryBlur' />
      </div>
      {showPop && (
        <>
          <div className='summaryPopupBC' onClick={() => setShowPop(false)} />
          <div className='summaryPopup'>
            <div className='summaryPopupHeader'>
              <div />
              <Close onClick={() => setShowPop(false)} className='summaryPopupClose' />
            </div>
            <div className='summaryPopupTitle'>Your motivation level:</div>
            <div className='summaryPopupHigh'>High</div>
            <div className='summaryPopupNormal'>Normal</div>
            <div className='summaryPopupLow'>
              <div className='summaryPopupLowText'>Low</div>
              <div className='summaryPopupLowText'>20</div>
            </div>
            <div className='summaryPopupText'>
              Low motivation can hinder your ability to reach your goals and negatively impact your overall
              well-being. It can lead to procrastination, decreased productivity, and a lack of drive to make
              positive changes in your life. However, making simple adjustments to your routine can quickly fix
              that.
            </div>
          </div>
        </>
      )}
      <div className='componentFirst'>
        <div className='componentSecond' style={{ justifyContent: 'center' }}>
          <div className='quiz'>
            {/* Header */}
            <div className='quizHeader'>
              <div className='quizHeaderSide '></div>
              <div className='quizHeaderSide'>
                <div className='logo'>
                  <Logo />
                </div>
              </div>
              <div className='quizHeaderSide'></div>
            </div>

            {/* Main */}

            <div className='summaryTitle smallBox'>
              Congratulations! We have created your personalized program to get you motivated and help reach your
              dreams and goals.
            </div>

            <div className='smallText summarySubTitle smallBox'>
              You can realistically increase your motivation and thus chances of getting what you want in 12 weeks.
            </div>

            <div className='summarySmallTitle smallBox'>
              Your motivation level: <span style={{ color: '#DD5047' }}>20 (low)</span>
            </div>

            <div className='summaryUnderLine smallBox' onClick={showPopup}>
              What does this mean?
            </div>

            <img src={summaryImage} alt='' className='summaryImage' />

            <div className='summaryButtonContainer' style={{ background: 'none' }}>
              <div
                className='mainContinue smallBox'
                style={{ marginTop: '20px' }}
                onClick={() => navigate('/email')}
              >
                INCREASE MY MOTIVATION
              </div>
            </div>

            <div className='summaryTitle smallBox'>
              Stop wasting time. Improve your motivation levels in weeks.
            </div>

            <div className='summaryInfoBox smallBox'>
              <div className='summaryInfoBoxTitle'>83%</div>
              <div className='summaryInfoBoxText'>
                of users with similar profile have got closer to their goals and dreams, and feel more motivated
                than ever.
              </div>
            </div>

            <div className='summaryInfoBox smallBox'>
              <div className='summaryInfoBoxTitle'>30</div>
              <div className='summaryInfoBoxText'>
                estimated increase in motivation levels after first 28 days.
              </div>
            </div>

            <div className='summaryInfoBox smallBox'>
              <div className='summaryInfoBoxTitle'>91%</div>
              <div className='summaryInfoBoxText'>of our members got rid of procrastination in 12 weeks.</div>
            </div>

            <div className='mainContinue smallBox' onClick={() => navigate('/email')}>
              START NOW
            </div>

            <div className='summaryCustom'>
              <div className='summaryCustomTitle'>
                Utilize behavioral science to help you make lasting changes.
              </div>

              <div className='summaryCustomItem'>
                <Pursuitful />
                <div className='smallText summaryCustomItemText'>No more wasted time.</div>
              </div>

              <div className='summaryCustomItem'>
                <Pursuitful />
                <div className='smallText summaryCustomItemText'>No more self-sabotage.</div>
              </div>

              <div className='summaryCustomItem'>
                <Pursuitful />
                <div className='smallText summaryCustomItemText'>No more living purposelessly.</div>
              </div>
            </div>

            <div className='summaryBox'>
              <div className='summaryBoxTitle'>Pursuitful's techniques were covered in:</div>
              <img src={forbes} alt='' className='summaryBoxImg' />
              <img src={NewTimes} alt='' className='summaryBoxImg' />
              <img src={business} alt='' className='summaryBoxImg' />
            </div>

            <div className='summaryTitle smallBox'>Your program includes:</div>

            <div className='summaryInfoBox smallBox'>
              <SummaryOne />
              <div className='summaryInfoIncludesTitle'>1</div>
              <div className='summaryInfoBoxText'>
                daily personalized visual of your dream to rewire your brain towards goal-seeking behaviors. You
                can set these visuals as your phone, computer or social media backgrounds.
              </div>
            </div>

            <div className='summaryInfoBox smallBox'>
              <SummaryUnlimited />
              <div className='summaryInfoIncludesTitle'>Unlimited</div>
              <div className='summaryInfoBoxText'>
                personalized audio affirmations to eliminate procrastination. Eradicate limiting beliefs and boost
                self-esteem.
              </div>
            </div>

            <div className='summaryInfoBox smallBox'>
              <SummaryDaily />
              <div className='summaryInfoIncludesTitle'>Daily</div>
              <div className='summaryInfoBoxText'>tips and tricks to get you closer to your goals.</div>
            </div>

            <div className='mainContinue smallBox' onClick={() => navigate('/email')}>
              START NOW
            </div>
          </div>
        </div>
      </div>
      <div className='summaryFooter '>
        <div className='componentSecond' style={{ justifyContent: 'center' }}>
          <div className='quiz  ' style={{ paddingBottom: '100px' }}>
            <div className='summaryTitle smallBox'>Our promise</div>
            <div className='smallText summarySubTitle smallBox'>
              We understand the struggle of feeling unmotivated and not seeing yourself get any closer to your
              goals. We've all been there..
            </div>

            <div className='smallText summarySubTitle smallBox'>
              It's easy to become overwhelmed and give up. The journey can often feel too daunting to even start.
              After all,
              <span className='summaryFooterText'> we're talking about your biggest goals and dreams</span> .
            </div>
            <div className='smallText summarySubTitle smallBox'>
              That's why we developed{' '}
              <span className='summaryFooterText'>
                a program to boost motivation and help you achieve your deepest desires. It's based on over 50+
                scientific studies and took years to develop
              </span>
              .
            </div>
            <div className='smallText summarySubTitle smallBox'>
              It's designed to help you understand your core motivations, and use them to propel you forward.
            </div>
            <div className='smallText summarySubTitle smallBox'>
              It will give you the tools to set attainable goals, stay organized, and hold yourself accountable.
            </div>
            <div className='smallText summarySubTitle smallBox'>
              It will provide you with the guidance to celebrate small successes and stay motivated throughout the
              process.
            </div>
            <div className='smallText summarySubTitle smallBox'>
              Finally, it will help you develop the confidence to
              <span className='summaryFooterText'> achieve your ultimate goal</span>.
            </div>
            <div className='smallText summarySubTitle smallBox'>
              We're confident that this program will help you find the motivation to start the journey, and the
              enthusiasm to see it through to the end.
            </div>
            <div className='summaryTitle smallBox'>Science & Research Team at Pursuitful</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Summary
