import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import './loader.css'

import BC from '../../Images/Pursuitful.webp'
import loaderImage from '../../Images/loaderImage.png'

import { ReactComponent as Logo } from '../../icons/Logo.svg'

import ReactGA from 'react-ga4'

const Loader = () => {
  const navigate = useNavigate()

  const [timer, setTimer] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer < 100) {
        setTimer((v) => v + 0.5)
      } else {
        navigate('/summary')
      }
    }, 25)

    return () => {
      clearInterval(interval)
    }
  }, [timer, navigate])

  useEffect(() => {
    ReactGA.event({ category: 'page', action: 'Loader' })
  }, [])

  return (
    <div className='componentFirst'>
      <div className='BC'>
        <img src={BC} alt='' className='BCImage' />
      </div>
      <div className='BC'>
        <div className='BCImageBlur' />
      </div>
      <div className='componentSecond' style={{ justifyContent: 'center' }}>
        <div className='quiz'>
          {/* Header */}
          <div className='quizHeader'>
            <div className='quizHeaderSide '></div>
            <div className='quizHeaderSide'>
              <div className='logo'>
                <Logo />
              </div>
            </div>
            <div className='quizHeaderSide'></div>
          </div>

          {/* Main */}
          <div className='loaderTitle'>The power of methodical motivation engineering.</div>

          <img src={loaderImage} alt='' className='loaderImage' />

          <div className='loaderBarContainer'>
            <div className='loaderBar'>
              <div className='loaderProgress' style={{ width: `${timer}%` }} />
            </div>
            <div className='loaderBarText'>Preparing your results</div>
          </div>
          <div className='quizCustom'>
            <div className='loaderCustomText'>
              Did you know that, on average, most goals can be finished within 3-12 months?
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loader
