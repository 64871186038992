import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useArrayContext } from '../../contexts/GlobalArray'

import BC from '../../Images/Pursuitful.webp'
import PaymentOptions from '../../Images/PaymentOptions.png'

import { ReactComponent as Logo } from '../../icons/Logo.svg'
import { ReactComponent as BackIcon } from '../../icons/BackIcon.svg'
import { ReactComponent as PayPal } from '../../icons/PayPal.svg'
import { ReactComponent as Close } from '../../icons/Close.svg'

import { formatCreditCardNumber, formatCVC, formatExpirationDate } from './utils'

import './payment.css'

import ReactGA from 'react-ga4'

const Payment = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { plan, downSellSelected } = useArrayContext()

  const [selectedOption, setSelectedOption] = useState(true)

  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [date, setDate] = useState('')
  const [cvv, setCvv] = useState('')

  const [showPopup, setShowPopup] = useState(false)

  const [nextStep, setNextStep] = useState(false)

  window.history.pushState(null, null, location.href)
  window.onpopstate = function () {
    navigate('/downsell')
  }

  useEffect(() => {
    ReactGA.event({ category: 'page', action: 'Payment' })
  }, [])

  const handleInputChange = (e) => {
    if (e.target.name === 'number') {
      e.target.value = formatCreditCardNumber(e.target.value)
      setNumber(e.target.value)
    } else if (e.target.name === 'expiry') {
      e.target.value = formatExpirationDate(e.target.value)
      setDate(e.target.value)
    } else if (e.target.name === 'cvc') {
      e.target.value = formatCVC(e.target.value)
      setCvv(e.target.value)
    } else {
      setName(e.target.value || '')
    }
  }

  useEffect(() => {
    if (name === undefined && number === undefined && date === undefined && cvv === undefined) {
      return
    }
    if (name.length > 3 && number.length === 19 && date.length === 5 && cvv.length === 3) {
      setNextStep(true)
    } else {
      setNextStep(false)
    }
  }, [name, number, date, cvv])

  const MoveBack = () => {
    navigate('/downsell')
  }

  const handleNextStep = () => {
    if (!nextStep) return

    setShowPopup(true)

    ReactGA.event({
      category: 'page',
      action: `TRY TO PAY ${plan === 1 ? '6 month' : plan === 2 ? '3 month' : '1 month'} ${
        downSellSelected ? 'downsell' : ''
      }`,
    })
  }

  return (
    <div className='componentFirst'>
      <div className='BC'>
        <img src={BC} alt='' className='BCImage' />
      </div>
      {showPopup && (
        <>
          <div className='summaryPopupBC' />
          <div className='paymentPopup'>
            <div className='paymentPopupIcon'>
              <Close onClick={() => setShowPopup(false)} style={{ padding: '7px' }} />
            </div>
            <div className='paymentPopupHeader'>Apologies! Your payment cannot be processed at the moment</div>
            <div className='paymentPopupText'>
              Don't worry! You haven't been charged. We will reach out to you via email whenever our product is
              available for purchase again
            </div>
          </div>
        </>
      )}
      <div className='BC'>
        <div className='BCImageBlur' />
      </div>
      <div className='componentSecond' style={{ justifyContent: 'center' }}>
        <div className='quiz' style={{ maxWidth: '440px' }}>
          {/* Header */}
          <div className='quizHeader'>
            <div className='quizHeaderSide '>
              <BackIcon onClick={MoveBack} />
            </div>
            <div className='quizHeaderSide'>
              <div className='logo'>
                <Logo />
              </div>
            </div>
            <div className='quizHeaderSide'></div>
          </div>

          <div className='paymentBox'>
            <div className='paymentTitle'>Order summary</div>
            <div className='paymentPlan'>
              <div className='paymentPlanTitleContainer'>
                <div className='paymentPlanText'>Pursuitful</div>
                <div className='paymentPlanText'>${plan === 1 ? '364' : plan === 2 ? '169' : '52'}</div>
              </div>
              <div className='paymentPlanText'>
                {plan === 1 ? '6-month plan' : plan === 2 ? '3-month plan' : '1-month plan'}
              </div>
              <div className='paymentPlanTitleContainer'>
                <div className='paymentPlanTextDiscount'>
                  {downSellSelected ? (
                    <>Discount (-{plan === 1 ? '89' : plan === 2 ? '70' : '50'}%)</>
                  ) : (
                    <>Discount (-{plan === 1 ? '80' : plan === 2 ? '64' : '42'}%)</>
                  )}
                </div>
                <div className='paymentPlanTextDiscount'>
                  {downSellSelected ? (
                    <>-${plan === 1 ? '324' : plan === 2 ? '118' : '26'}</>
                  ) : (
                    <>-${plan === 1 ? '286' : plan === 2 ? '107' : '22'}</>
                  )}
                </div>
              </div>
              <div className='paymentPlanLine' />
              <div className='paymentPlanTitleContainer'>
                <div className='paymentPlanFooterText'>Total</div>
                <div className='paymentPlanFooterText'>
                  {downSellSelected ? (
                    <>${plan === 1 ? '40' : plan === 2 ? '51' : '26'}</>
                  ) : (
                    <>${plan === 1 ? '78' : plan === 2 ? '62' : '30'}</>
                  )}
                </div>
              </div>
              <div className='paymentPlanFooter'>Billed every 6 months</div>
            </div>

            <div className='paymentTitle' style={{ marginTop: '24px' }}>
              Select a secure payment method
            </div>

            <div className='paymentPlan paymentOption' onClick={() => setSelectedOption(true)}>
              <div className='paymentOptionItem'>
                <div
                  className={selectedOption ? 'paymentPlanSelectOption' : 'paymentPlanSelectOptionUnselected'}
                />
                <div className='paymentPlanSelectText'>Credit Card</div>
              </div>
              <div className='paymentPlanSelect'>
                <img src={PaymentOptions} alt='' className='paymentIcon' />
              </div>
            </div>

            <div className='paymentPlan paymentOption' onClick={() => setSelectedOption(false)}>
              <div className='paymentOptionItem'>
                <div
                  className={selectedOption ? 'paymentPlanSelectOptionUnselected' : 'paymentPlanSelectOption'}
                />
                <div className='paymentPlanSelectText'>Paypal</div>
              </div>
              <div className='paymentPlanSelect'>
                <PayPal />
              </div>
            </div>

            <div className='paymentTitle' style={{ marginTop: '24px' }}>
              Payment information
            </div>

            {selectedOption ? (
              <div className='paymentPlan'>
                <div className='paymentPlanInputTitle'>Cardholder Name</div>
                <input
                  type='text'
                  className='paymentPlanInputBox'
                  placeholder='Cardholder Name'
                  name='name'
                  onChange={(e) => handleInputChange(e)}
                  pattern='[a-z A-Z-]+'
                />
                <div className='paymentPlanInputTitle'>Card Number</div>
                <input
                  className='paymentPlanInputBox'
                  type='tel'
                  pattern='[\d| ]{16,22}'
                  placeholder='&#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226;'
                  maxLength='19'
                  name='number'
                  onChange={(e) => handleInputChange(e)}
                />
                <div className='paymentPlanInputTitle'>
                  Expiration Date <span className='paymentTitlePlaceholder'>(MM/YY)</span>
                </div>
                <input
                  type='tel'
                  className='paymentPlanInputBox'
                  pattern='\d\d/\d\d'
                  placeholder='MM/YY'
                  maxLength='7'
                  name='expiry'
                  onChange={(e) => handleInputChange(e)}
                />
                <div className='paymentPlanInputTitle'>
                  CVV <span className='paymentTitlePlaceholder'>(3 digits)</span>
                </div>
                <input
                  type='tel'
                  className='paymentPlanInputBox'
                  pattern='\d{3}'
                  placeholder='&#8226;&#8226;&#8226;'
                  maxLength='4'
                  name='cvc'
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            ) : (
              <div className='paymentTitle' style={{ margin: '40px 0', textAlign: 'center' }}>
                Payment method not available
              </div>
            )}

            <div
              className={nextStep ? 'paymentButton paymentButtonActive' : 'paymentButton'}
              onClick={handleNextStep}
            >
              Submit secure payment
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Payment
