import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { ReactComponent as Logo } from '../../icons/Logo.svg'
import { ReactComponent as Money } from '../../icons/Money.svg'
import { ReactComponent as Body } from '../../icons/Body.svg'
import { ReactComponent as Plane } from '../../icons/Plane.svg'
import { ReactComponent as House } from '../../icons/House.svg'
import { ReactComponent as Heart } from '../../icons/Heart.svg'

import BC from '../../Images/Pursuitful.webp'

import './main.css'
import { useArrayContext } from '../../contexts/GlobalArray'

import { landingPageEvent } from '../../contexts/facebookPixelEvent'

import ReactGA from 'react-ga4'

const Main = () => {
  const navigate = useNavigate()

  const { answer, answerTitle, setAnswerTitle, setAnswer } = useArrayContext()

  const [selectedOptionMultiple, setSelectedOptionMultiple] = useState([])
  const [reload, setReload] = useState(false)

  useEffect(() => {
    ReactGA.event({ category: 'page', action: 'Main' })
    landingPageEvent()
  }, [])

  const AddMultipleOption = (index) => {
    if (selectedOptionMultiple.includes(index)) {
      setSelectedOptionMultiple(selectedOptionMultiple.filter((a) => a !== index))
    } else {
      selectedOptionMultiple.push(index)
      setReload(!reload)
    }
  }

  const NextPage = () => {
    var arr = [...answerTitle]
    arr[0] = 'Choose your goals:'
    setAnswerTitle(arr)

    let options = []

    if (selectedOptionMultiple.includes(0)) {
      options.push('Financial security')
    }
    if (selectedOptionMultiple.includes(1)) {
      options.push('Fit body')
    }
    if (selectedOptionMultiple.includes(2)) {
      options.push('Traveling more')
    }
    if (selectedOptionMultiple.includes(3)) {
      options.push('Owning a home')
    }
    if (selectedOptionMultiple.includes(4)) {
      options.push('Finding true love')
    }

    var arrItem = [...answer]
    arrItem[0] = options
    setAnswer(arrItem)

    navigate('/quiz')
  }
  return (
    <div className='componentFirst'>
      <div className='BC'>
        <img src={BC} alt='' className='BCImage' />
      </div>
      <div className='componentSecond'>
        <div className='main'>
          <div className='logo'>
            <Logo />
          </div>
          <div className='mainTitle'>Get the most popular program that turns goals and dreams into reality</div>
          <div className='mainSubTitle'>
            Eliminate procrastination and lack of motivation with scientifically proven personalized motivation
            boosting methods
          </div>
          <div className='customBox'>
            <div className='customBoxText'>
              156,892+ people ordered personalized programs to reach goals and dreams faster
            </div>
          </div>

          <div className='mainGoals'>Choose your goals:</div>

          <div
            className={selectedOptionMultiple.includes(0) ? 'mainListItem mainListItemSelected' : 'mainListItem'}
            onClick={() => AddMultipleOption(0)}
          >
            <div className='mainListItemIcon'>
              <Money className='mainListIcon' />
            </div>
            <div className='mainListItemText'>
              <span style={{ fontWeight: 'bold' }}>Material possesions </span>
              like cars, houses & watches
            </div>
          </div>

          <div
            className={selectedOptionMultiple.includes(1) ? 'mainListItem mainListItemSelected' : 'mainListItem'}
            onClick={() => AddMultipleOption(1)}
          >
            <div className='mainListItemIcon'>
              <Plane className='mainListIcon' />
            </div>
            <div className='mainListItemText'>
              <span style={{ fontWeight: 'bold' }}>Experiences </span> like traveling & skydiving
            </div>
          </div>

          <div
            className={selectedOptionMultiple.includes(2) ? 'mainListItem mainListItemSelected' : 'mainListItem'}
            onClick={() => AddMultipleOption(2)}
          >
            <div className='mainListItemIcon'>
              <Body className='mainListIcon' />
            </div>
            <div className='mainListItemText'>
              <span style={{ fontWeight: 'bold' }}>Improved appearance or health </span>
              like losing or gaining weight & building new habits
            </div>
          </div>

          <div
            className={selectedOptionMultiple.includes(3) ? 'mainListItem mainListItemSelected' : 'mainListItem'}
            onClick={() => AddMultipleOption(3)}
          >
            <div className='mainListItemIcon'>
              <House className='mainListIcon' />
            </div>
            <div className='mainListItemText'>
              <span style={{ fontWeight: 'bold' }}>Financial security </span>
              (making/saving more money)
            </div>
          </div>

          <div
            className={selectedOptionMultiple.includes(4) ? 'mainListItem mainListItemSelected' : 'mainListItem'}
            onClick={() => AddMultipleOption(4)}
          >
            <div className='mainListItemIcon'>
              <Heart className='mainListIcon' />
            </div>
            <div className='mainListItemText'>
              <span style={{ fontWeight: 'bold' }}>Spirituality </span> (finding true love or inner peace)
            </div>
          </div>

          <div
            className={selectedOptionMultiple.length > 0 ? 'mainContinue' : 'mainContinue mainContinueHide'}
            onClick={NextPage}
          >
            Continue
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main
