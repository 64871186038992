import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './email.css'

import BC from '../../Images/Pursuitful.webp'

import { ReactComponent as Logo } from '../../icons/Logo.svg'
import { ReactComponent as EmailSVG } from '../../icons/Email.svg'
import { ReactComponent as PersonalData } from '../../icons/PersonalData.svg'
import { useArrayContext } from '../../contexts/GlobalArray'
import { ReactComponent as BackIcon } from '../../icons/BackIcon.svg'

import ReactGA from 'react-ga4'

import SteinStore from 'stein-js-client'

const store = new SteinStore('https://api.steinhq.com/v1/storages/63ff69f7eced9b09e9c1b8ae')

const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i

const Email = () => {
  const navigate = useNavigate()
  const [emailValid, setEmailValid] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')

  const { answer, answerTitle, setAnswerTitle, setAnswer } = useArrayContext()

  useEffect(() => {
    if (emailPattern.test(emailAddress)) {
      setEmailValid(true)
    } else {
      setEmailValid(false)
    }
  }, [emailAddress, setEmailAddress])

  useEffect(() => {
    ReactGA.event({ category: 'page', action: 'Email' })
  }, [])

  const NextPage = () => {
    let arr = [...answerTitle]
    arr[13] = 'Email'
    setAnswerTitle(arr)

    var arrItemText = [...answer]
    arrItemText[13] = emailAddress
    setAnswer(arrItemText)

    SendEmail()
  }

  const MoveBack = () => {
    navigate('/summary')
  }

  const SendEmail = () => {
    //let arrT = [...answerTitle]
    let arr = [...answer]

    const value = {
      A1: arr[0],
      A2: arr[1],
      A3: arr[2],
      A4: arr[3],
      A5: arr[4],
      A6: arr[5],
      A7: arr[6],
      A8: arr[7],
      A9: arr[8],
      A10: arr[9],
      A11: arr[10],
      A12: arr[11],
      A13: arr[12],
      A14: emailAddress,
    }

    store
      .append('Sheet1', [
        {
          email: emailAddress,
          main: value.A1 === undefined ? '' : value.A1.join(', '),
          quizAge: value.A2,
          quizGender: value.A3,
          quizChallenges: value.A4 === undefined ? '' : value.A4.join(', '),
          quizStatement: value.A5,
          quizGoal: value.A6 === undefined ? '' : value.A6.join(', '),
          quizInput: value.A7,
          quizReachGoal: value.A8,
          quizStatement2: value.A9,
          quizWhyReachGoal: value.A10,
          quizStatement3: value.A11,
          quizGoalIn12Months: value.A12,
          quizDidntReachGoal: value.A13,
        },
      ])
      .then((res) => {
        // console.log(res)
      })

    navigate('/product')
  }
  return (
    <div className='componentFirst'>
      <div className='BC'>
        <img src={BC} alt='' className='BCImage' />
      </div>
      <div className='BC'>
        <div className='BCImageBlur' />
      </div>
      <div className='componentSecond' style={{ justifyContent: 'center' }}>
        <div className='quiz'>
          <div className='quizHeader'>
            <div className='quizHeaderSide '>
              <BackIcon onClick={MoveBack} />
            </div>
            <div className='quizHeaderSide'>
              <div className='logo'>
                <Logo />
              </div>
            </div>
            <div className='quizHeaderSide'></div>
          </div>
          <div className='summaryTitle'>Where should we send access details to your personalized program?</div>
          <div className='mainSubTitle'>
            We've created a<span className='emailBold'> scientifically proven program </span>
            that will help take you to your goals and dreams.
          </div>

          <div className='emailInputTitle'>Enter your email address</div>

          <div className='emailInputContainer'>
            <EmailSVG />
            <input
              type='text'
              placeholder='Your email'
              className='emailInput'
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </div>

          <div className='emailPersonalData'>
            <PersonalData className='emailPersonalDataIcon' />
            <div className='emailPersonalDataText'>
              Your personal data is safe with us. We don’t send spam or share email addresses with third parties.
            </div>
          </div>

          {emailValid && (
            <div className='mainContinue' onClick={NextPage}>
              Next
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Email
