import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import BC from '../../Images/Pursuitful.webp'
import women from '../../Images/women.webp'

import { ReactComponent as Logo } from '../../icons/Logo.svg'
import { ReactComponent as BackIcon } from '../../icons/BackIcon.svg'
import { ReactComponent as Star } from '../../icons/Star.svg'
import { ReactComponent as Alternatives } from '../../icons/Alternatives.svg'
import { ReactComponent as Pursuitful } from '../../icons/Pursuitful.svg'

import quizData from '../../assets/data/quiz'

import './quiz.css'
import { useArrayContext } from '../../contexts/GlobalArray'
import ReactGA from 'react-ga4'
import { purchase } from '../../contexts/facebookPixelEvent'

const length = quizData.length + 1

const Quiz = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [start, setStart] = useState(true)
  const [index, setIndex] = useState(0)
  const [data, setData] = useState({})
  const [progressBar, setProgressBar] = useState(0)
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedOptionMultiple, setSelectedOptionMultiple] = useState([])
  const [reload, setReload] = useState(false)

  const [textInput, setTextInput] = useState('')

  const { answer, answerTitle, setAnswerTitle, setAnswer } = useArrayContext()

  window.history.pushState(null, null, location.href)
  window.onpopstate = function () {
    MoveBack()
  }

  useEffect(() => {
    setData(quizData[index])

    if (start) {
      setProgressBar(0)
    } else {
      setProgressBar((100 / length) * (index + 1))
    }
  }, [index, start])

  useEffect(() => {
    ReactGA.event({ category: 'page', action: 'Quiz' })
    purchase('USD', 30.0)
  }, [])

  const MoveBack = () => {
    if (index === 0 && start) {
      navigate('/')
    }
    if (index === 0) {
      setStart(true)
    } else if (index > 0) {
      setIndex(index - 1)
    }
  }

  const NextStep = () => {
    if (data.textInput) {
      let arr = [...answerTitle]
      arr[data.number] = data.title
      setAnswerTitle(arr)

      var arrItemText = [...answer]
      arrItemText[data.number] = textInput
      setAnswer(arrItemText)
    }
    if (data.multiple) {
      let arr = [...answerTitle]
      arr[data.number] = data.title
      setAnswerTitle(arr)

      let array = []

      selectedOptionMultiple.map((item) => array.push(data.options[item]))

      var arrItem = [...answer]
      arrItem[data.number] = array
      setAnswer(arrItem)
    }
    if (length - 2 === index) {
      navigate('/loader')
      return
    }
    ReactGA.event({ category: 'page', action: `Quiz Question ${index}` })
    setIndex(index + 1)
    setSelectedOption(null)
    setSelectedOptionMultiple([])
  }

  const AddMultipleOption = (index) => {
    if (selectedOptionMultiple.includes(index)) {
      setSelectedOptionMultiple(selectedOptionMultiple.filter((a) => a !== index))
    } else {
      selectedOptionMultiple.push(index)
      setReload(!reload)
    }
  }

  const OnSelectOption = (title, option, index) => {
    var arr = [...answerTitle]
    arr[data.number] = title
    setAnswerTitle(arr)

    var arrItem = [...answer]
    arrItem[data.number] = option
    setAnswer(arrItem)

    setSelectedOption(index)

    setTimeout(() => {
      NextStep()
    }, 700)
  }

  return (
    <div className='componentFirst'>
      <div className='BC'>
        <img src={BC} alt='' className='BCImage' />
      </div>
      <div className='BC'>
        <div className='BCImageBlur' />
      </div>
      <div className='componentSecond' style={{ justifyContent: 'center' }}>
        <div className='quiz'>
          {/* Header */}
          <div className='quizHeader'>
            <div className='quizHeaderSide '>
              <BackIcon onClick={MoveBack} />
            </div>
            <div className='quizHeaderSide'>
              <div className='logo'>
                <Logo />
              </div>
            </div>
            <div className='quizHeaderSide'></div>
          </div>
          {/* ProgressBar */}
          <div className='quizBar'>
            <div className='quizBarProgress' style={{ width: `${progressBar}%` }} />
          </div>

          {start ? (
            <div className='quizContainer'>
              <div className='quizTitle'>Welcome to Pursuitful.</div>
              <div className='smallText quizSubTitle'>
                <span className='quizSubTitleHighlighted'>Congratulations </span>
                on taking the first step towards a{' '}
                <span className='quizSubTitleHighlighted'> mentally stronger you</span>.
              </div>
              <div className=' smallText quizSubTitle'>
                Let’s get started! Just a few questions to{' '}
                <span className='quizSubTitleHighlighted'>personalize</span> your dreams and goals.
              </div>

              <div className='quizCustom'>
                <div className='quizCustomTitle'>Did you know?</div>
                <div className='smallText'>
                  <span className='quizSubTitleHighlighted'>84% </span>
                  of <span className='quizSubTitleHighlighted'>Pursuitful</span> users who used the app 5 times a
                  week saw an improvement in their <span className='quizSubTitleHighlighted'>motivation </span>
                  and <span className='quizSubTitleHighlighted'>energy levels</span>.
                </div>
              </div>

              <div className='mainContinue' onClick={() => setStart(false)}>
                Continue
              </div>
            </div>
          ) : (
            <div className='quizContainer'>
              <div className='quizTitle'>{data.title}</div>

              {!data.custom ? (
                <div>
                  {data.statement ? (
                    <div className='quizStatement'>
                      <div className='quizStatementText'>"{data.subTitle}"</div>
                    </div>
                  ) : (
                    <div className='smallText quizSubTitle'>{data.subTitle}</div>
                  )}
                  {data.multiple ? (
                    <div>
                      <div className='smallText'>{data.multipleText}</div>
                      {data.options.map((item, index) => (
                        <div
                          className={
                            selectedOptionMultiple.includes(index) ? 'quizOption quizOptionSelected' : 'quizOption'
                          }
                          key={index}
                          onClick={() => AddMultipleOption(index)}
                        >
                          <div className='quizOptionText'>{item}</div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    data.options.map((item, index) => (
                      <div
                        className={selectedOption === index ? 'quizOption quizOptionSelected' : 'quizOption'}
                        key={index}
                        onClick={() => OnSelectOption(data.title, item, index)}
                      >
                        <div className='quizOptionText'>{item}</div>
                      </div>
                    ))
                  )}
                </div>
              ) : (
                <div>
                  {data.customId === 1 ? (
                    <div>
                      <div className='customBox'>
                        <div className='customBoxText'>
                          It helps improve performance, and increase self-confidence and concentration.
                        </div>
                        <div className='customBoxFooterText'>M.M. Guadagnoli & E.J. Wilk, 1990</div>
                      </div>
                      <div className='customBox'>
                        <div className='customBoxText'>
                          It helps increase motivation and confidence, as well as reduce anxiety.
                        </div>
                        <div className='customBoxFooterText'>T.A. Landers, 2012</div>
                      </div>
                      <div className='customBox'>
                        <div className='customBoxText'>
                          Changing your mental and physical environments helps increase motivation.
                        </div>
                        <div className='customBoxFooterText'>K. Lewin</div>
                      </div>
                    </div>
                  ) : data.customId === 2 ? (
                    <div>
                      <div className='customBox'>
                        <div className='customBoxText'>
                          <span className='customBoxTextSmall'>
                            Motivation to achieve a goal encourages us to develop new techniques and skills in
                            order to succeed (Locke, 2001).{' '}
                          </span>
                          Hence, the higher the motivation, the more likely you are to achieve your goals.
                        </div>
                      </div>
                      <div className='quizCustomBigText'>
                        Pursuitful uses science to rewire your brain and increase motivation by sending daily
                        visualisations of your goal, providing personalized motivation-boosting audio affirmations,
                        and personalized tips that help you achieve your goals.
                      </div>
                    </div>
                  ) : data.customId === 3 ? (
                    <div className='quizMiddle'>
                      <img src={women} alt='' className='quizMiddleImage' />
                      <Star />
                      <div className='quizCustomBigTextMiddle'>
                        "I thought getting daily doses of inspiration & tips wouldn't help, but I'm the closest to
                        my goals than I have ever been!"
                      </div>
                      <div className='smallText'>Louise S.</div>
                    </div>
                  ) : (
                    data.customId === 4 && (
                      <div>
                        <div className='quizEndingTitle'>Alternatives:</div>
                        <div className='quizEndingItem'>
                          <Alternatives className='quizEndingIcon' />
                          <div className='smallText quizEndingText'>Time-consuming</div>
                        </div>
                        <div className='quizEndingItem'>
                          <Alternatives className='quizEndingIcon' />
                          <div className='smallText quizEndingText'>Repetitive and boring</div>
                        </div>
                        <div className='quizEndingItem'>
                          <Alternatives className='quizEndingIcon' />
                          <div className='smallText quizEndingText'>90%+ quit after 1 week</div>
                        </div>
                        <div className='customBox'>
                          <div className='quizEndingTitle'>Pursuitful:</div>
                          <div className='quizEndingItem'>
                            <Pursuitful className='quizEndingIcon' />
                            <div className='smallText quizEndingText'>Only 5 min a day</div>
                          </div>
                          <div className='quizEndingItem'>
                            <Pursuitful className='quizEndingIcon' />
                            <div className='smallText quizEndingText'>Always surprising</div>
                          </div>
                          <div className='quizEndingItem'>
                            <Pursuitful className='quizEndingIcon' />
                            <div className='smallText quizEndingText'>
                              Users feel more motivated, confident, and procrastinate less in 1-3 weeks
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  {data.textInput && (
                    <div>
                      <div className='quizTextInputContainer'>
                        <textarea
                          type='text'
                          className='quizTextInput'
                          placeholder='Type here'
                          value={textInput}
                          onChange={(v) => setTextInput(v.target.value)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
              {data.required ? (
                data.multiple ? (
                  <div
                    className={
                      selectedOptionMultiple.length > 0 ? 'mainContinue' : 'mainContinue mainContinueHide'
                    }
                    onClick={NextStep}
                  >
                    Continue
                  </div>
                ) : (
                  <div
                    className={
                      selectedOption !== null ? 'mainContinue mainContinueHide' : 'mainContinue mainContinueHide'
                    }
                    onClick={NextStep}
                  >
                    Continue
                  </div>
                )
              ) : (
                <div className='mainContinue' onClick={NextStep}>
                  Continue
                </div>
              )}
              {data.textInput && (
                <div className='quizSkipbutton' onClick={NextStep}>
                  Skip for now
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Quiz
