import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './product.css'

import BC from '../../Images/summaryBC.webp'
import productImageOne from '../../Images/productImageOne.png'
import ProductReviewOne from '../../Images/ProductReviewOne.png'
import ProductReviewTwo from '../../Images/ProductReviewTwo.png'
import ProductReviewThree from '../../Images/ProductReviewThree.png'

import { ReactComponent as Logo } from '../../icons/Logo.svg'
import { ReactComponent as Stars } from '../../icons/Stars.svg'
import { ReactComponent as One } from '../../icons/One.svg'
import { ReactComponent as Two } from '../../icons/Two.svg'
import { ReactComponent as Three } from '../../icons/Three.svg'
import { ReactComponent as CancelAny } from '../../icons/CancelAny.svg'
import { ReactComponent as Cheaper } from '../../icons/Cheaper.svg'
import { ReactComponent as Pursuitful } from '../../icons/Pursuitful.svg'
import { ReactComponent as FAQArrow } from '../../icons/FAQArrow.svg'

import ReactGA from 'react-ga4'
import { useArrayContext } from '../../contexts/GlobalArray'

const Product = () => {
  const navigate = useNavigate()

  const { plan, setPlan } = useArrayContext()

  const [sidebarTop, setSidebarTop] = useState(undefined)
  const [openFAQ, setOpenFAQ] = useState(0)
  const [selectedPlan, setSelectedPlan] = useState(plan)

  const [minutes, setMinutes] = useState(10)
  const [seconds, setSeconds] = useState(0)

  const [nextStep, setNextStep] = useState(false)

  useEffect(() => {
    setPlan(selectedPlan)
    if (selectedPlan !== 0) {
      setNextStep(true)
    }
  }, [selectedPlan, setPlan])

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((seconds) => seconds - 1)
      } else if (minutes > 0) {
        setMinutes((minutes) => minutes - 1)
        setSeconds(59)
      } else {
        setMinutes(10)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [seconds, minutes])

  useEffect(() => {
    ReactGA.event({ category: 'page', action: 'Product' })
  }, [])

  const OnFaqClick = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(0)
    } else {
      setOpenFAQ(index)
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' })
  }, [])

  useEffect(() => {
    const chatEl = document.querySelector('.productTimer').getBoundingClientRect()
    setSidebarTop(chatEl.top)

    // const { innerHeight: height } = window
    // const bottom = document.querySelector('.stickyToBottom').getBoundingClientRect()
    // setBottomTop(bottom.top - height + 100)
  }, [])

  useEffect(() => {
    if (!sidebarTop) return

    const isSticky = () => {
      const chatEl = document.querySelector('.productTimer')
      const hiddenElement = document.querySelector('.productTimerhidden')
      // const bottomElement = document.querySelector('.stickyToBottom')
      const scrollTop = window.scrollY
      if (scrollTop >= sidebarTop) {
        chatEl.classList.add('is-sticky')
        hiddenElement.classList.add('productTimerhiddenShow')
      } else {
        hiddenElement.classList.remove('productTimerhiddenShow')
        chatEl.classList.remove('is-sticky')
      }
    }

    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  }, [sidebarTop])

  const handleNextStep = () => {
    if (nextStep) {
      navigate('/payment')
    }
  }

  return (
    <>
      <div className='summaryBC' style={{ minHeight: '4650px' }}>
        <img src={BC} alt='' className='BCImage' />
      </div>
      <div className='BC'>
        <div className='BCSummaryBlur' />
      </div>

      <div className='componentFirst'>
        <div className='componentSecond' style={{ justifyContent: 'center' }}>
          <div className='quiz'>
            {/* Header */}
            <div className='quizHeader'>
              <div className='quizHeaderSide '></div>
              <div className='quizHeaderSide'>
                <div className='logo'>
                  <Logo />
                </div>
              </div>
              <div className='quizHeaderSide'></div>
            </div>

            {/* Main */}

            <div className='summaryTitle smallBox'>Get your personal motivation-boosting program.</div>

            <div className='smallText summarySubTitle smallBox'>
              The most popular motivation and productivity tool.
              <span className='summaryFooterText'> 156,892 programs ordered</span> .
            </div>

            <div className='productCustom'>
              <div className='productCustomLeft'>
                <Stars className='productCustomIcon' />
                <div className='smallText'>4,7/5</div>
              </div>
              <div className='smallText'>5,000+ reviews</div>
            </div>

            <div className='productSteps'>
              <div className='productStepsLeft'>
                <div className='productStepsNumber'>
                  <One />
                </div>
                <div className='productStepsLine' />
                <div className='productStepsNumberOutline'>
                  <Two />
                </div>
                <div className='productStepsLine' style={{ height: '92px' }} />
                <div className='productStepsNumberOutline'>
                  <Three />
                </div>
              </div>
              <div className='productStepsRight'>
                <div>
                  <div className='productStepsTitle'>Your personalized goal accelerating program is ready.</div>
                  <div className='productStepsBox'>
                    🎉 Congratulations! You have already completed the 1st step!
                  </div>
                </div>

                <div className='productStepsItem'>
                  <div className='productStepsTitle'>Open the app – start rewiring your brain for success.</div>
                  <div className='smallText'>Get motivated. Eliminate procrastination. Feel inspired.</div>
                </div>

                <div>
                  <div className='productStepsTitle'>Watch your habits and mental patterns transform.</div>
                  <div className='smallText'>
                    Get closer and closer to your goals using Pursuitful's personalized systems.
                  </div>
                </div>
              </div>
            </div>

            <div className='productTimer' style={{ width: '327px' }}>
              <div className='smallText'>
                <span className='summaryFooterText'>80% discount </span> expires in:
              </div>
              <div className='productTimerTimer'>
                {minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}
              </div>
            </div>
            <div className='productTimerhidden' />

            <div className='productBox'>
              <div className='summaryTitle' style={{ marginTop: 0 }}>
                Select your plan:
              </div>

              <div
                className={
                  selectedPlan === 3 ? 'productPlanOption productPlanOptionSelected' : 'productPlanOption'
                }
                onClick={() => setSelectedPlan(3)}
              >
                <div className='productPlanOptionLeft'>
                  <div
                    className={selectedPlan === 3 ? 'productPlanOptionCircleSelected' : 'productPlanOptionCircle'}
                  />
                  <div className='productPlanOptionTextContainer'>
                    <div className='productPlanOptionPlanFor'>1-month plan</div>
                    <div className='productPlanOptionPlanForTime'>Monthly App subscription</div>
                  </div>
                </div>
                <div className='productPlanOptionRight'>
                  <div className='productPlanOptionOldPrice'>$13.00</div>
                  <div className='productPlanOptionNewPrice'>$7.61</div>
                  <div className='productPlanOptionTime'>per week</div>
                </div>
              </div>

              <div
                className={
                  selectedPlan === 1 ? 'productPlanOption productPlanOptionSelected' : 'productPlanOption'
                }
                style={{ marginBottom: '27px' }}
                onClick={() => setSelectedPlan(1)}
              >
                <div className='productPlanOptionLeft'>
                  <div
                    className={selectedPlan === 1 ? 'productPlanOptionCircleSelected' : 'productPlanOptionCircle'}
                  />
                  <div className='productPlanOptionTextContainer'>
                    <div className='productPlanOptionPlanFor'>6-month plan</div>
                    <div className='productPlanOptionPlanForTime'>6-month App subscription.</div>
                  </div>
                </div>
                <div className='productPlanOptionRight'>
                  <div className='productPlanOptionOldPrice'>$13.00</div>
                  <div className='productPlanOptionNewPrice'>$2.79</div>
                  <div className='productPlanOptionTime'>per week</div>
                </div>

                <div className='productPlanOptionSpecial'>SPECIAL OFFER - SAVE 80%</div>
              </div>

              <div
                className={
                  selectedPlan === 2 ? 'productPlanOption productPlanOptionSelected' : 'productPlanOption'
                }
                onClick={() => setSelectedPlan(2)}
              >
                <div className='productPlanOptionLeft'>
                  <div
                    className={selectedPlan === 2 ? 'productPlanOptionCircleSelected' : 'productPlanOptionCircle'}
                  />
                  <div className='productPlanOptionTextContainer'>
                    <div className='productPlanOptionPlanFor'>3-month plan</div>
                    <div className='productPlanOptionPlanForTime'>Quaterly App subscription.</div>
                  </div>
                </div>
                <div className='productPlanOptionRight'>
                  <div className='productPlanOptionOldPrice'>$13.00</div>
                  <div className='productPlanOptionNewPrice'>$4.77</div>
                  <div className='productPlanOptionTime'>per week</div>
                </div>
              </div>

              <div className='productBoxSmallText'>
                By choosing a payment method, you agree to the Terms & Conditions and Privacy Poilicy
              </div>

              <div className='productBoxOptions'>
                <CancelAny />
                Cancel anytime, no hidden fees.
              </div>
              <div className='productBoxOptions'>
                <Cheaper />
                Cheaper than your daily cup of coffee.
              </div>

              <div className='mainContinue' onClick={handleNextStep}>
                GET NOW
              </div>
            </div>

            <div className='productBoxPlans'>
              <div className='summaryTitle' style={{ marginTop: '16px' }}>
                Select your plan:
              </div>

              <div className='summaryCustomItem'>
                <Pursuitful className='productCustomIcon' />
                <div className='smallText summaryCustomItemText'>Daily dose of motivation.</div>
              </div>

              <div className='summaryCustomItem'>
                <Pursuitful className='productCustomIcon' />
                <div className='smallText summaryCustomItemText'>Easy-to-use app.</div>
              </div>

              <div className='summaryCustomItem'>
                <Pursuitful className='productCustomIcon' />
                <div className='smallText summaryCustomItemText'>Scientifically proven methods.</div>
              </div>

              <div className='summaryCustomItem'>
                <Pursuitful className='productCustomIcon' />
                <div className='smallText summaryCustomItemText'>Focuses on rewiring subconscious.</div>
              </div>

              <div className='summaryCustomItem'>
                <Pursuitful className='productCustomIconMargin' />
                <div className='smallText summaryCustomItemText'>Techniques used by most successful people.</div>
              </div>

              <div className='summaryCustomItem'>
                <Pursuitful className='productCustomIcon' />
                <div className='smallText summaryCustomItemText'>Practical tips & tricks.</div>
              </div>

              <div className='summaryCustomItem'>
                <Pursuitful className='productCustomIcon' />
                <div className='smallText summaryCustomItemText'>Instant progress tracker.</div>
              </div>

              <div className='summaryCustomItem'>
                <Pursuitful className='productCustomIcon' />
                <div className='smallText summaryCustomItemText'>Personalized audio affirmations.</div>
              </div>

              <div className='summaryCustomItem'>
                <Pursuitful className='productCustomIcon' />
                <div className='smallText summaryCustomItemText'>Accountability levers.</div>
              </div>

              <div className='summaryCustomItem'>
                <Pursuitful className='productCustomIcon' />
                <div className='smallText summaryCustomItemText'>Visualisation techniques.</div>
              </div>
            </div>

            <div className='summaryTitle smallBox'>
              A step-by-step journey to boost motivation and achieve goals easier and faster.
            </div>

            <img src={productImageOne} alt='' />

            <div className='mainContinue smallBox' onClick={handleNextStep}>
              GET NOW
            </div>

            <div className='summaryTitle smallBox'>
              Join thousands who have fulfilled their dreams and goals with Pursuitful!
            </div>

            <div className='productReview smallBox'>
              <div className='productReviewImage'>
                <img src={ProductReviewOne} alt='' />
              </div>
              <div className='productReviewTitle'>I was feeling completely distracted and unmotivated...</div>
              <div className='productReviewText'>
                Then I discovered Pursuitful on Instagram. With its helpful reminders, I find myself finally
                inspired to keep pursuing my dreams!
              </div>
              <div className='productReviewName'>Emma J. 25</div>
            </div>

            <div className='productReview smallBox'>
              <div className='productReviewImage'>
                <img src={ProductReviewTwo} alt='' />
              </div>
              <div className='productReviewTitle'>
                I always knew I'm capable of something more, something bigger.
              </div>
              <div className='productReviewText'>
                These daily reminders and tips literally destroyed my procrastination. I know exactly what I want,
                and I'm going to get it.
              </div>
              <div className='productReviewName'>Tom B. 32</div>
            </div>

            <div className='productReview smallBox'>
              <div className='productReviewImage'>
                <img src={ProductReviewThree} alt='' />
              </div>
              <div className='productReviewTitle'>
                To be completely honest, I thought this is some kind of... bull*hit.
              </div>
              <div className='productReviewText'>
                I was super skeptical. I thought, "What exactly does this app do?" But I'm a huge believer that
                everything starts from motivation, so I gave it a try. Best decision ever. It takes only 1-5 min a
                day, but reminds you of your purpose and goals. Epic app.
              </div>
              <div className='productReviewName'>Nelly L. 28</div>
            </div>

            <div className='productFAQHeader'>
              <div className='summaryCustomItem'>
                <Pursuitful className='productCustomIcon' />
                <div className='smallText summaryCustomItemText'>Based on 20+ scientific studies.</div>
              </div>
              <div className='summaryCustomItem'>
                <Pursuitful className='productCustomIconMargin' />
                <div className='smallText summaryCustomItemText'>
                  Techniques used by top athletes, entrepreneurs, celebrities, and scientists.
                </div>
              </div>
              <div className='summaryCustomItem'>
                <Pursuitful className='productCustomIcon' />
                <div className='smallText summaryCustomItemText'>Easy, practical, and quick.</div>
              </div>
            </div>

            <div className='productBox'>
              <div className='mainContinue' style={{ marginTop: 0 }} onClick={handleNextStep}>
                GET NOW
              </div>

              <div className='summaryTitle' style={{ marginTop: '40px' }}>
                People often ask us...
              </div>

              <div className='productFAQItem'>
                <div className='productFAQItemHeader' onClick={() => OnFaqClick(1)}>
                  <div className='productFAQTitle'>What is Pursuitful?</div>
                  <div className='productFAQIcon'>
                    <FAQArrow className={openFAQ === 1 ? 'productFAQItemIconOpen' : 'productFAQItemIconClose'} />
                  </div>
                </div>
                <div className={openFAQ === 1 ? 'productFAQItemShow' : ' productFAQItemShow productFAQItemHidden'}>
                  <div className='smallText'>
                    Pursuitful is an app that provides personalized techniques for achieving your goals with
                    features such as daily personalized visualizations, audio affirmations, and motivational
                    system.
                  </div>
                </div>
              </div>

              <div className='productFAQItem'>
                <div className='productFAQItemHeader' onClick={() => OnFaqClick(2)}>
                  <div className='productFAQTitle'>What happens after I order?</div>
                  <div className='productFAQIcon'>
                    <FAQArrow className={openFAQ === 2 ? 'productFAQItemIconOpen' : 'productFAQItemIconClose'} />
                  </div>
                </div>
                <div className={openFAQ === 2 ? 'productFAQItemShow' : ' productFAQItemShow productFAQItemHidden'}>
                  <div className='smallText'>
                    You immediatelly get access to the Pursutfuil app in which you will find your personalized
                    motivation boosting program.
                  </div>
                </div>
              </div>

              <div className='productFAQItem'>
                <div className='productFAQItemHeader' onClick={() => OnFaqClick(3)}>
                  <div className='productFAQTitle'>Who prepared the program?</div>
                  <div className='productFAQIcon'>
                    <FAQArrow className={openFAQ === 3 ? 'productFAQItemIconOpen' : 'productFAQItemIconClose'} />
                  </div>
                </div>
                <div className={openFAQ === 3 ? 'productFAQItemShow' : ' productFAQItemShow productFAQItemHidden'}>
                  <div className='smallText'>
                    Our Research & Science team with extensive knowledge in human behaviour and motivation systems.
                  </div>
                </div>
              </div>

              <div className='productFAQItem'>
                <div className='productFAQItemHeader' onClick={() => OnFaqClick(4)}>
                  <div className='productFAQTitle'>How can I be sure that this program will actually work?</div>
                  <div className='productFAQIcon'>
                    <FAQArrow className={openFAQ === 4 ? 'productFAQItemIconOpen' : 'productFAQItemIconClose'} />
                  </div>
                </div>
                <div className={openFAQ === 4 ? 'productFAQItemShow' : ' productFAQItemShow productFAQItemHidden'}>
                  <div className='smallText'>
                    We use scientifically proven techniques to help you achieve your goals and dreams. These
                    techniques have transformed thousands of lives around the world.
                  </div>
                </div>
              </div>
            </div>

            <div className='productFAQFooter'>
              <div className='productFAQFooterText'>© Pursuitful 2023. All Rights Reserved.</div>
              <div className='productFAQFooterText'>
                Results may vary due to personal features. These statements have not been evaluated by Food and
                Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease.
                www.pursuitful.io clearly states that it is not medical organisation and cannot give the customer
                any medical advice or assistance. The customer is solely responsible for evaluating and assessing
                his own health.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Product
