const quizData = [
  {
    id: 1,
    required: true,
    custom: false,
    statement: false,
    title: 'How old are you?',
    subTitle: 'This helps us find the best content for you. It is private and will not be shared.',
    multiple: false,
    multipleText: '',
    textInput: false,
    options: ['Under 26', '26-35', '36-45', '46-55', 'Over 55'],
    number: 1,
  },
  {
    id: 2,
    required: true,
    custom: false,
    statement: false,
    title: 'What is your gender?',
    subTitle: 'This helps us find the best content for you. It is private and will not be shared.',
    multiple: false,
    multipleText: '',
    textInput: false,
    options: ['Male', 'Female', 'Other'],
    number: 2,
  },
  {
    id: 3,
    required: true,
    custom: false,
    statement: false,
    title: 'What are the biggest challenges you anticipate in reaching your goal?',
    subTitle: '',
    multiple: true,
    multipleText: 'Select all that apply',
    textInput: false,
    options: [
      'I don’t believe in myself',
      'Fear of failure',
      'Lack of knowledge/skills',
      'Unclear goals and objectives',
      'Procrastination',
    ],
    number: 3,
  },
  {
    id: 4,
    required: true,
    custom: false,
    statement: true,
    title: 'Do you agree with the following statement?',
    subTitle:
      "If I held more positive beliefs about my abilities and my future, I'd be more likely  to reach my goal.",
    multiple: false,
    multipleText: '',
    textInput: false,
    options: ['Agree', 'Neutral', 'Disagree'],
    number: 4,
  },
  {
    id: 5,
    required: true,
    custom: false,
    statement: false,
    title: 'Have you tried any of these goal-setting techniques?',
    subTitle: '',
    multiple: true,
    multipleText: 'Select all that apply',
    textInput: false,
    options: [
      'Writing goals down',
      'Visualizing goals',
      'Breaking goals down',
      'Setting rewards',
      'Rewiring brain with positive personalized audio affirmations',
      'None of the above',
    ],
    number: 5,
  },
  {
    id: 6,
    required: false,
    custom: true,
    statement: false,
    title: 'Is visualisation actually effective?',
    subTitle: '',
    multiple: false,
    multipleText: '',
    textInput: false,
    customId: 1,
    options: [],
  },
  {
    id: 7,
    required: false,
    custom: true,
    statement: false,
    title: 'Please specify your goal.',
    subTitle: '',
    multiple: false,
    multipleText: '',
    textInput: true,
    customId: 0,
    options: [],
    number: 6,
  },
  {
    id: 8,
    required: true,
    custom: false,
    statement: false,
    title: 'When would you like to reach your goal?',
    subTitle: '',
    multiple: false,
    multipleText: '',
    textInput: false,
    options: ['In 1-6 months', 'In 6-12 months', 'In 1-3 years', 'In 3+ years'],
    number: 7,
  },
  {
    id: 9,
    required: true,
    custom: false,
    statement: true,
    title: 'Do you agree with the following statement?',
    subTitle:
      'If there was a way to increase my motivation to reach my goal, I’d be more likely to find ways to achieve it.',
    multiple: false,
    multipleText: '',
    textInput: false,
    options: ['Agree', 'Neutral', 'Disagree'],
    number: 8,
  },
  {
    id: 10,
    required: false,
    custom: true,
    statement: false,
    title: 'Did you know?',
    subTitle: '',
    multiple: false,
    multipleText: '',
    textInput: false,
    customId: 2,
    options: [],
  },
  {
    id: 11,
    required: true,
    custom: false,
    statement: false,
    title: 'Why do you want to reach your goal?',
    subTitle: '',
    multiple: false,
    multipleText: '',
    textInput: false,
    options: [
      'To be happy and live meaningfully',
      'To boost self-esteem',
      'To be recognized',
      'To create positive impact',
    ],
    number: 9,
  },
  {
    id: 12,
    required: true,
    custom: false,
    statement: true,
    title: 'Do you agree with the following statement?',
    subTitle: "If I thought more about my goal, I'd be more likely to reach it.",
    multiple: false,
    multipleText: '',
    textInput: false,
    options: ['Agree', 'Neutral', 'Disagree'],
    number: 10,
  },
  {
    id: 13,
    required: false,
    custom: true,
    statement: false,
    title: '',
    subTitle: '',
    multiple: false,
    multipleText: '',
    textInput: false,
    customId: 3,
    options: [],
  },
  {
    id: 14,
    required: true,
    custom: false,
    statement: false,
    title: 'How would you feel if you reached your goal in 12 months?',
    subTitle: '',
    multiple: false,
    multipleText: '',
    textInput: false,
    options: ['Happy, excited, fulfilled', "I'd simply set another goal"],
    number: 11,
  },
  {
    id: 15,
    required: true,
    custom: false,
    statement: false,
    title: "How would you feel if you didn't reach your goal?",
    subTitle: '',
    multiple: false,
    multipleText: '',
    textInput: false,
    options: ["I wouldn't care a lot and move on", 'Disappointed and demotivated', 'Sad, but would try again'],
    number: 12,
  },
  {
    id: 16,
    required: false,
    custom: true,
    statement: false,
    title: 'We know our techniques work.',
    subTitle: '',
    multiple: false,
    multipleText: '',
    textInput: false,
    customId: 4,
    options: [],
  },
]

export default quizData
