import React, { createContext, useContext, useState } from 'react'

const ArrayContext = createContext({
  answerTitle: undefined,
  answer: undefined,
})

const GlobalArrayProvider = ({ children }) => {
  const [answerTitle, setAnswerTitle] = useState([])
  const [answer, setAnswer] = useState([])

  const [downSellSelected, setDownSellSelected] = useState(false)

  const [plan, setPlan] = useState(1)

  return (
    <ArrayContext.Provider
      value={{
        answerTitle,
        answer: answer,
        setAnswerTitle,
        setAnswer: setAnswer,
        plan,
        setPlan,
        downSellSelected,
        setDownSellSelected: setDownSellSelected,
      }}
    >
      {children}
    </ArrayContext.Provider>
  )
}

export default GlobalArrayProvider
export const useArrayContext = () => useContext(ArrayContext)
