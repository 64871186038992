import ReactGA from 'react-ga4'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import GlobalArrayProvider from './contexts/GlobalArray'

import Downsell from './page/downsell/Downsell'
import Email from './page/email/Email'
import Loader from './page/loader/Loader'

import Main from './page/main/Main'
import Payment from './page/payment/Payment'
import Product from './page/product/Product'
import Quiz from './page/quiz/Quiz'
import Summary from './page/summary/Summary'

ReactGA.initialize('G-WVX9PMJGRS')

const App = () => {
  return (
    <BrowserRouter>
      <GlobalArrayProvider>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/quiz' element={<Quiz />} />
          <Route path='/loader' element={<Loader />} />
          <Route path='/summary' element={<Summary />} />
          <Route path='/email' element={<Email />} />
          <Route path='/product' element={<Product />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/downsell' element={<Downsell />} />
        </Routes>
      </GlobalArrayProvider>
    </BrowserRouter>
  )
}

export default App
