import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BC from '../../Images/summaryBC.webp'
import downsellImage from '../../Images/downsellImage.webp'

import { ReactComponent as Logo } from '../../icons/Logo.svg'
import { ReactComponent as CancelAny } from '../../icons/CancelAny.svg'
import { ReactComponent as Cheaper } from '../../icons/Cheaper.svg'

import './downsell.css'

import ReactGA from 'react-ga4'
import { useArrayContext } from '../../contexts/GlobalArray'

const Downsell = () => {
  const navigate = useNavigate()

  const { plan, setPlan, setDownSellSelected } = useArrayContext()

  const [selectedPlan, setSelectedPlan] = useState(plan)

  useEffect(() => {
    ReactGA.event({ category: 'page', action: 'Downsell' })
  }, [])

  useEffect(() => {
    setDownSellSelected(true)
  }, [setDownSellSelected])

  useEffect(() => {
    setPlan(selectedPlan)
  }, [selectedPlan, setPlan])

  return (
    <>
      <div className='summaryBC' style={{ minHeight: '1417px' }}>
        <img src={BC} alt='' className='BCImage' />
      </div>
      <div className='BC'>
        <div className='BCSummaryBlur' />
      </div>

      <div className='componentFirst'>
        <div className='componentSecond' style={{ justifyContent: 'center' }}>
          <div className='quiz'>
            {/* Header */}
            <div className='quizHeader'>
              <div className='quizHeaderSide '></div>
              <div className='quizHeaderSide'>
                <div className='logo'>
                  <Logo />
                </div>
              </div>
              <div className='quizHeaderSide'></div>
            </div>

            {/* Main */}

            <div className='summaryTitle smallBox'>Wait! You’ve just been approved for the 89% discount!</div>

            <div className='smallText summarySubTitle smallBox'>
              Grab this exclusive one-time offer before the time runs out. The offer is not available at any other
              place or time.
            </div>

            <div className='smallText summarySubTitle smallBox'>
              72% of our users who started their plan
              <span className='summaryFooterText'> drastically reduced procrastination levels</span> in 28 days.
            </div>

            <img src={downsellImage} alt='' className='downsellImg' />

            <div className='productBox'>
              <div className='summaryTitle' style={{ marginTop: 0 }}>
                Select your plan:
              </div>

              <div
                className={
                  selectedPlan === 3 ? 'productPlanOption productPlanOptionSelected' : 'productPlanOption'
                }
                onClick={() => setSelectedPlan(3)}
              >
                <div className='productPlanOptionLeft'>
                  <div
                    className={selectedPlan === 3 ? 'productPlanOptionCircleSelected' : 'productPlanOptionCircle'}
                  />
                  <div className='productPlanOptionTextContainer'>
                    <div className='productPlanOptionPlanFor'>1-month plan</div>
                    <div className='productPlanOptionPlanForTime'>Monthly App subscription</div>
                  </div>
                </div>
                <div className='productPlanOptionRight'>
                  <div className='productPlanOptionOldPrice'>$13.00</div>
                  <div className='productPlanOptionNewPrice'>$6.49</div>
                  <div className='productPlanOptionTime'>per week</div>
                </div>
              </div>

              <div
                className={
                  selectedPlan === 1 ? 'productPlanOption productPlanOptionSelected' : 'productPlanOption'
                }
                style={{ marginBottom: '27px' }}
                onClick={() => setSelectedPlan(1)}
              >
                <div className='productPlanOptionLeft'>
                  <div
                    className={selectedPlan === 1 ? 'productPlanOptionCircleSelected' : 'productPlanOptionCircle'}
                  />
                  <div className='productPlanOptionTextContainer'>
                    <div className='productPlanOptionPlanFor'>6-month plan</div>
                    <div className='productPlanOptionPlanForTime'>6-month App subscription.</div>
                  </div>
                </div>
                <div className='productPlanOptionRight'>
                  <div className='productPlanOptionOldPrice'>$13.00</div>
                  <div className='productPlanOptionNewPrice'>$1.43</div>
                  <div className='productPlanOptionTime'>per week</div>
                </div>

                <div className='productPlanOptionSpecial'>SPECIAL OFFER - SAVE 80%</div>
              </div>

              <div
                className={
                  selectedPlan === 2 ? 'productPlanOption productPlanOptionSelected' : 'productPlanOption'
                }
                onClick={() => setSelectedPlan(2)}
              >
                <div className='productPlanOptionLeft'>
                  <div
                    className={selectedPlan === 2 ? 'productPlanOptionCircleSelected' : 'productPlanOptionCircle'}
                  />
                  <div className='productPlanOptionTextContainer'>
                    <div className='productPlanOptionPlanFor'>3-month plan</div>
                    <div className='productPlanOptionPlanForTime'>Quaterly App subscription.</div>
                  </div>
                </div>
                <div className='productPlanOptionRight'>
                  <div className='productPlanOptionOldPrice'>$13.00</div>
                  <div className='productPlanOptionNewPrice'>$3.89</div>
                  <div className='productPlanOptionTime'>per week</div>
                </div>
              </div>

              <div className='productBoxSmallText'>
                By choosing a payment method, you agree to the Terms & Conditions and Privacy Poilicy
              </div>

              <div className='productBoxOptions'>
                <CancelAny />
                Cancel anytime, no hidden fees.
              </div>
              <div className='productBoxOptions'>
                <Cheaper />
                Cheaper than your daily cup of coffee.
              </div>

              <div className='mainContinue' onClick={() => navigate('/payment')}>
                GET NOW
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Downsell
